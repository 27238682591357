import { motion, AnimatePresence } from 'framer-motion';
import { HiChevronDown } from 'react-icons/hi';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { useState } from 'react';

function Experience({ isDarkMode, gygPreviewData, setActivePreview }) {
  const [showPrevious, setShowPrevious] = useState(false);
  const [failedFavicons, setFailedFavicons] = useState(new Set());

  const getFaviconUrl = (domain) => `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;

  const handleFaviconError = (domain) => {
    setFailedFavicons(prev => new Set([...prev, domain]));
  };

  const FaviconOrFallback = ({ domain, company }) => {
    if (failedFavicons.has(domain)) {
      return <HiOutlineBuildingOffice2 className="w-3.5 h-3.5 mt-1" />;
    }
    
    return (
      <img 
        src={getFaviconUrl(domain)} 
        alt={company}
        className="w-3.5 h-3.5 mt-1"
        style={{ imageRendering: 'crisp-edges' }}
        onError={() => handleFaviconError(domain)}
      />
    );
  };

  return (
    <motion.section 
      className="mb-12"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <h2 className="text-gray-500 text-sm mb-4">
        CURRENTLY
      </h2>
      
      {/* Current Role */}
      <div className="flex justify-between items-start mb-4">
        <div className="w-1/2 space-y-1">
          <span className="font-bold block">Product Marketing & Comms</span>
          <span className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
            2024 - Present
          </span>
        </div>
        <div className="flex items-start gap-2">
          <FaviconOrFallback domain="getyourguide.com" company="GYG" />
          <div className="flex flex-col items-end">
            <span className="text-gray-500">GetYourGuide</span>
            <span className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
              Berlin
            </span>
          </div>
        </div>
      </div>

      {/* Previous Roles Button */}
      <motion.button
        onClick={() => setShowPrevious(!showPrevious)}
        className={`flex items-center gap-2 text-xs mb-2 ${
          isDarkMode ? 'text-gray-500 hover:text-gray-400' : 'text-gray-400 hover:text-gray-500'
        } transition-colors`}
      >
        <motion.span
          animate={{ rotate: showPrevious ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <HiChevronDown />
        </motion.span>
        Previous roles
      </motion.button>

      {/* Previous Roles List */}
      <AnimatePresence>
        {showPrevious && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className={`pl-4 border-l ${
              isDarkMode ? 'border-gray-800' : 'border-gray-200'
            }`}
          >
            <div className="space-y-3">
              {previousRoles.map((role, index) => (
                <div key={index} className="flex justify-between items-start w-full">
                  <div className="w-1/2 space-y-0.5">
                    <span className="block text-xs">{role.title}</span>
                    <span className={`block text-[10px] ${
                      isDarkMode ? 'text-gray-500' : 'text-gray-400'
                    }`}>
                      {role.period}
                    </span>
                  </div>
                  <div className="flex items-start gap-2">
                    <FaviconOrFallback domain={role.domain} company={role.company} />
                    <div className="flex flex-col items-end">
                      <span className="text-gray-500 text-xs">{role.company}</span>
                      <span className={`text-[10px] ${
                        isDarkMode ? 'text-gray-500' : 'text-gray-400'
                      }`}>
                        {role.location}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.section>
  );
}

const previousRoles = [
  {
    title: "Employer Brand Lead",
    period: "2022 - 2024",
    company: "GetYourGuide",
    domain: "getyourguide.com",
    location: "Berlin"
  },
  {
    title: "Employer Brand Lead",
    period: "2020 - 2021",
    company: "Agoda",
    domain: "agoda.com",
    location: "Bangkok"
  },
  {
    title: "Employer Brand & Comms Manager",
    period: "2019 - 2020",
    company: "Blinkit",
    domain: "blinkit.com",
    location: "Gurgaon"
  },
  {
    title: "Director of Growth",
    period: "2017 - 2019",
    company: "Tarinika",
    domain: "tarinika.com",
    location: "Gurgaon"
  },
  {
    title: "Social, Content, Brand Marketer",
    period: "2015 - 2019",
    company: "HCL Technologies",
    domain: "hcltech.com",
    location: "Noida"
  }
];

export default Experience;
