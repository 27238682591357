import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HiSun, HiMoon, HiArrowPath } from 'react-icons/hi2';
import { format } from 'date-fns';
import useSound from 'use-sound';
import gameSound from '../sounds/game.mp3';
import { useFont } from '../App';
import { useEasterEggs } from '../contexts/EasterEggContext';

const Nav = ({ isDarkMode, setIsDarkMode, setIsCommandPaletteOpen }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [colonVisible, setColonVisible] = useState(true);
  const [weatherData, setWeatherData] = useState(null);
  const [userLocation, setUserLocation] = useState('Berlin');
  const [isLoading, setIsLoading] = useState(true);
  const [playGameSound] = useSound(gameSound);
  const { fontFamily, setFontFamily } = useFont();
  const { 
    discoverEgg, 
    discoveredEggs, 
    totalPoints, 
    progress,
    showAchievement,
    setShowAchievement,
    currentAchievement,
    resetEasterEggs 
  } = useEasterEggs();

  // Add a ref to track if sound has played
  const soundPlayedRef = useRef(false);

  // Add state for custom reset modal
  const [showResetModal, setShowResetModal] = useState(false);

  // Check if all eggs are discovered
  const allEggsDiscovered = progress.earnedPoints === progress.possiblePoints;

  // Update time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      setColonVisible(prev => !prev);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Improved font switch trigger
  const handleFontSwitch = () => {
    const newFont = fontFamily === 'default' ? 'inter' : 'default';
    setFontFamily(newFont);
    
    // Only trigger on first switch
    if (!discoveredEggs.FONT_SWITCH) {
      discoverEgg('FONT_SWITCH');
    }
  };

  // Add location fetching
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) throw new Error('Location fetch failed');
        const data = await response.json();
        setUserLocation(data.city || 'Berlin');
      } catch (error) {
        console.error('Error fetching location:', error);
        setUserLocation('Berlin'); // Fallback to Berlin
      }
    };
    fetchLocation();
  }, []);

  // Update weather fetching to use fetch instead of axios
  useEffect(() => {
    const fetchWeather = async () => {
      if (!userLocation) return;
      
      try {
        setIsLoading(true);
        const response = await fetch(`/api/weather?city=${userLocation}`);
        if (!response.ok) throw new Error('Weather fetch failed');
        const data = await response.json();
        setWeatherData(data);
      } catch (error) {
        console.error('Error fetching weather:', error);
        setWeatherData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWeather();
  }, [userLocation]);

  return (
    <>
      <motion.nav 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="inline-flex"
      >
        <div className={`
          flex items-center gap-3 px-3 py-2 rounded-full
          ${isDarkMode 
            ? 'bg-black/40 text-gray-400' 
            : 'bg-white/40 text-gray-500'
          }
          backdrop-blur-xl border
          ${isDarkMode 
            ? 'border-white/[0.08]' 
            : 'border-black/[0.08]'
          }
        `}>
          {/* Command palette - Already hidden on mobile */}
          <button 
            onClick={() => setIsCommandPaletteOpen(true)}
            className="flex items-center gap-1.5 text-sm hover:text-gray-300 transition-colors"
          >
        
            <kbd className={`
              hidden sm:inline px-1.5 py-0.5 text-[11px] rounded font-["Inter"]
              ${isDarkMode 
                ? 'bg-white/[0.06]' 
                : 'bg-black/[0.03]'
              }
            `}>
              ⌘ + K
            </kbd>
          </button>

          <div className="hidden sm:block h-3 w-[1px] bg-current opacity-10" />

          {/* Font switcher */}
          <button
            onClick={handleFontSwitch}
            className={`
              text-xs px-2 py-0.5 rounded-full group relative
              hover:bg-white/[0.06]
              transition-colors duration-200
              ${fontFamily === 'inter' 
                ? 'font-["Inter"] tracking-tight' 
                : 'font-default tracking-normal'
              }
            `}
          >
            Aa
            <span className={`
              absolute -bottom-8 left-1/2 -translate-x-1/2 
              px-2 py-1 rounded text-[10px] whitespace-nowrap
              ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}
              opacity-0 group-hover:opacity-100
              transition-opacity duration-200
              pointer-events-none
              hidden sm:block
            `}>
              Switch font
            </span>
          </button>

          <div className="h-3 w-[1px] bg-current opacity-10" />

          {/* Theme toggle */}
          <button
            onClick={() => setIsDarkMode(prev => !prev)}
            className="p-1 rounded-full hover:bg-white/[0.06] transition-colors group relative"
          >
            {isDarkMode ? (
              <HiSun className="w-3.5 h-3.5" />
            ) : (
              <HiMoon className="w-3.5 h-3.5" />
            )}
            <span className={`
              absolute -bottom-8 left-1/2 -translate-x-1/2 
              px-2 py-1 rounded text-[10px] whitespace-nowrap
              ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}
              opacity-0 group-hover:opacity-100
              transition-opacity duration-200
              pointer-events-none
              hidden sm:block
            `}>
              Press 'L'
            </span>
          </button>

          <div className="h-3 w-[1px] bg-current opacity-10" />

          {/* Time display */}
          <div className="text-xs font-mono">
            {format(currentTime, 'HH')}
            <span className={colonVisible ? 'opacity-100' : 'opacity-0'}>:</span>
            {format(currentTime, 'mm')}
          </div>

          <div className="h-3 w-[1px] bg-current opacity-10" />

          {/* Weather display */}
          {!isLoading && weatherData && weatherData.main && (
            <div className="text-xs group relative">
              {Math.round(weatherData.main.temp)}°C
              <span className={`
                absolute -bottom-8 left-1/2 -translate-x-1/2 
                px-2 py-1 rounded text-[10px] whitespace-nowrap
                ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}
                opacity-0 group-hover:opacity-100
                transition-opacity duration-200
                pointer-events-none
                hidden sm:block
              `}>
                {userLocation}
              </span>
            </div>
          )}

          {/* Add divider before easter egg counter - only visible on desktop */}
          <div className="hidden sm:block h-3 w-[1px] bg-current opacity-10" />

          {/* Points counter - Hide on mobile */}
          <div className="hidden sm:flex items-center gap-2 text-xs group relative">
            <div className="flex items-center gap-2">
              <span className={`
                font-medium
                ${allEggsDiscovered 
                  ? isDarkMode 
                    ? 'text-green-400 font-semibold' 
                    : 'text-green-600 font-semibold'
                  : ''}
              `}>
                {totalPoints}
              </span>
              <span className={`
                opacity-40
                ${allEggsDiscovered 
                  ? isDarkMode 
                    ? 'text-green-400' 
                    : 'text-green-600'
                  : ''}
              `}>
                / {progress.possiblePoints}
              </span>
            </div>
            
            {/* Info tooltip */}
            <span className={`
              absolute -bottom-8 left-1/2 -translate-x-1/2 
              px-2 py-1 rounded text-[10px] whitespace-nowrap
              ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}
              opacity-0 group-hover:opacity-100
              transition-opacity duration-200
              pointer-events-none
              hidden sm:block
              mb-1
            `}>
              Easter eggs discovered
            </span>
            
            {/* Reset button - Now positioned absolutely */}
            <motion.button
              onClick={() => setShowResetModal(true)}
              className={`
                absolute left-[calc(100%+4px)]
                opacity-0 group-hover:opacity-100
                transition-opacity duration-200
                hover:text-gray-300
                hidden sm:block
              `}
              whileHover={{ rotate: 180 }}
              transition={{ duration: 0.3 }}
            >
              <HiArrowPath className="w-3 h-3" />
            </motion.button>
          </div>
        </div>
      </motion.nav>

      {/* Achievement Popup with close button */}
      <AnimatePresence
        onExitComplete={() => {
          soundPlayedRef.current = false;
        }}
      >
        {showAchievement && currentAchievement && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={`
              fixed left-0 right-0 mx-auto bottom-8
              w-fit px-6 py-4 rounded-lg
              ${isDarkMode 
                ? 'bg-black/90' 
                : 'bg-white/90'
              }
              border
              ${isDarkMode 
                ? 'border-white/[0.08]' 
                : 'border-black/[0.08]'
              }
              backdrop-blur-xl shadow-lg
              flex items-center gap-4
              z-[9999]
            `}
            onAnimationStart={() => {
              if (!soundPlayedRef.current) {
                playGameSound();
                soundPlayedRef.current = true;
              }
            }}
          >
            <div className="text-2xl">
              {currentAchievement.icon && (
                <currentAchievement.icon 
                  className={`
                    w-6 h-6
                    ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}
                  `}
                />
              )}
            </div>
            <div>
              <div className="flex items-center gap-2">
                <h3 className="font-medium text-sm">
                  {currentAchievement.name}
                </h3>
                <span className={`
                  text-xs px-2 py-0.5 rounded-full
                  ${isDarkMode 
                    ? 'bg-green-500/20 text-green-400' 
                    : 'bg-green-100 text-green-600'
                  }
                `}>
                  +{currentAchievement.points}
                </span>
              </div>
              <p className={`
                text-sm mt-1
                ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}
              `}>
                {currentAchievement.description}
              </p>
            </div>
            <button
              onClick={() => setShowAchievement(false)}
              className={`
                absolute top-2 right-2
                p-1 rounded-full
                ${isDarkMode 
                  ? 'hover:bg-white/10' 
                  : 'hover:bg-black/10'
                }
                transition-colors
              `}
            >
              <svg className="w-3 h-3 opacity-50" viewBox="0 0 24 24">
                <path 
                  fill="currentColor" 
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
              </svg>
            </button>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Custom Reset Modal - centered using flex */}
      <AnimatePresence>
        {showResetModal && (
          <>
            <div 
              className="fixed inset-0 bg-black/60 backdrop-blur-sm z-[9999]"
              onClick={() => setShowResetModal(false)}
            />
            <div className="fixed inset-0 z-[9999] flex items-center justify-center">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className={`
                  p-6 rounded-lg mx-4 max-w-sm w-full
                  ${isDarkMode 
                    ? 'bg-black/90' 
                    : 'bg-white/90'
                  }
                  border
                  ${isDarkMode 
                    ? 'border-white/[0.08]' 
                    : 'border-black/[0.08]'
                  }
                  backdrop-blur-xl shadow-lg
                `}
              >
                <h3 className="text-lg font-medium mb-2">Reset Easter Eggs?</h3>
                <p className={`
                  text-sm mb-4
                  ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}
                `}>
                  This will reset all your discovered easter eggs and points. This action cannot be undone.
                </p>
                <div className="flex justify-end gap-3">
                  <button
                    onClick={() => setShowResetModal(false)}
                    className={`
                      px-3 py-1.5 rounded text-sm
                      ${isDarkMode 
                        ? 'hover:bg-white/10' 
                        : 'hover:bg-black/10'
                      }
                      transition-colors
                    `}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      resetEasterEggs();
                      setShowResetModal(false);
                    }}
                    className={`
                      px-3 py-1.5 rounded text-sm
                      ${isDarkMode 
                        ? 'bg-red-500/20 text-red-400 hover:bg-red-500/30' 
                        : 'bg-red-100 text-red-600 hover:bg-red-200'
                      }
                      transition-colors
                    `}
                  >
                    Reset
                  </button>
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Nav;
