import { useState, useEffect } from 'react';

export const useMetaPreviews = (projects) => {
  const [previews, setPreviews] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preloadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = reject;
      });
    };

    const fetchAllPreviews = async () => {
      try {
        const previewPromises = projects.map(project => 
          fetch(`https://api.microlink.io?url=${project.link}`)
            .then(res => res.json())
            .then(async data => {
              // Preload the image as soon as we get the preview data
              if (data.data?.image?.url) {
                await preloadImage(data.data.image.url);
              }
              return { [project.link]: data.data };
            })
        );

        const results = await Promise.all(previewPromises);
        const previewsMap = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setPreviews(previewsMap);
      } catch (error) {
        console.error('Error fetching previews:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllPreviews();
  }, [projects]);

  return { previews, loading };
};
