import { motion } from 'framer-motion';
import { HiArrowRight } from 'react-icons/hi';

function ProjectCard({ project, isDarkMode, onHoverStart, onHoverEnd, previewData }) {
  const handleHoverStart = () => {
    if (previewData) {
      onHoverStart(previewData);
    }
  };

  return (
    <motion.div
      className="relative"
      onHoverStart={handleHoverStart}
      onHoverEnd={onHoverEnd}
      whileHover={{ x: 4 }}
      transition={{ duration: 0.2 }}
    >
      <a 
        href={project.link} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="flex justify-between items-center w-full group"
      >
        <span className="font-bold">{project.name}</span>
        <HiArrowRight className="transform group-hover:translate-x-1 transition-transform" />
      </a>
    </motion.div>
  );
}

export default ProjectCard;
