import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect, createContext, useContext } from 'react';
import { HiCommandLine } from 'react-icons/hi2';
import { BrowserRouter as Router } from 'react-router-dom';
import { FiGithub, FiLinkedin, FiMail } from 'react-icons/fi';
import { BsTwitterX } from 'react-icons/bs';
import { FaSquareThreads } from 'react-icons/fa6';
import profilePic from './images/profile.png';
import profilePicLight from './images/profile-light.jpg';
import ProjectCard from './components/ProjectCard';
import Experience from './components/Experience';
import { Analytics } from '@vercel/analytics/react';
import Nav from './components/Nav';
import { useMetaPreviews } from './hooks/useMetaPreviews';
import CommandPalette from './components/CommandPalette';
import { PiLinkSimple } from "react-icons/pi";
import { EasterEggProvider, useEasterEggs } from './contexts/EasterEggContext';

// Projects
const PROJECTS = [
  { name: 'Radiocast', link: 'https://radiocast.co' },
  { name: 'Time', link: 'https://time.prateekkeshari.com' },
  { name: 'Scoop', link: 'https://scoop.prateekkeshari.com' },
  { name: 'Mockmint', link: 'https://mockmint.prateekkeshari.com' },
  { name: 'Peek AI', link: 'https://prateekkeshari.gumroad.com/l/peek' }
];

// Create font context
export const FontContext = createContext();

// Create a hook for using font
export const useFont = () => useContext(FontContext);

// Add this near the top of your App.js, after the imports
const shimmerClass = `
  hover:animate-shimmer bg-clip-text hover:text-transparent 
  hover:bg-[linear-gradient(110deg,#000000,30%,#ffffff,45%,#000000,55%,#ffffff,70%,#000000)] 
  dark:hover:bg-[linear-gradient(110deg,#ffffff,30%,#1e293b,45%,#ffffff,55%,#1e293b,70%,#ffffff)]
  light:hover:bg-[linear-gradient(110deg,#000000,30%,#ffffff,45%,#000000,55%,#ffffff,70%,#000000)]
  hover:bg-[length:300%_100%] transition-all duration-300 ease-in-out
`;

// Main App component
function App() {
  // Add fontFamily state
  const [fontFamily, setFontFamily] = useState(() => {
    const savedFont = localStorage.getItem('font');
    return savedFont || 'default';
  });

  return (
    <EasterEggProvider>
      <Router>
        <FontContext.Provider value={{ fontFamily, setFontFamily }}>
          <AppContent />
        </FontContext.Provider>
      </Router>
    </EasterEggProvider>
  );
}

// Create a wrapper component to use location and share context
function AppContent() {
  const { fontFamily } = useContext(FontContext);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : true;
  });
  const [activePreview, setActivePreview] = useState(null);
  const [gygPreviewData, setGygPreviewData] = useState(null);
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
  const [isSocialExpanded, setIsSocialExpanded] = useState(false);
  const [showEasterEgg, setShowEasterEgg] = useState(false);
  const { discoverEgg, discoveredEggs } = useEasterEggs();

  // Then use it in hooks
  const { previews } = useMetaPreviews(PROJECTS);

  // Add state for tracking Konami code
  const [konamiSequence, setKonamiSequence] = useState([]);
  const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];

  useEffect(() => {
    const handleKeyDown = (e) => {
      const newSequence = [...konamiSequence, e.key];
      if (newSequence.length > konamiCode.length) {
        newSequence.shift();
      }
      setKonamiSequence(newSequence);

      if (newSequence.join(',') === konamiCode.join(',')) {
        if (!discoveredEggs.KONAMI_CODE) {
          discoverEgg('KONAMI_CODE');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [konamiSequence, discoveredEggs, discoverEgg, konamiCode]);

  // Improved keyboard shortcuts handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Command palette trigger
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        setIsCommandPaletteOpen(true);
        if (!discoveredEggs.COMMAND_PALETTE) {
          discoverEgg('COMMAND_PALETTE');
        }
      }
      
      // Theme toggle trigger
      if (e.key.toLowerCase() === 'l' && !isCommandPaletteOpen && !e.metaKey && !e.ctrlKey) {
        e.preventDefault();
        setIsDarkMode(prev => !prev);
        if (!discoveredEggs.THEME_KEYBOARD) {
          discoverEgg('THEME_KEYBOARD');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isCommandPaletteOpen, discoveredEggs, discoverEgg]);

  // Add fetch for GetYourGuide
  useEffect(() => {
    const fetchGygPreview = async () => {
      try {
        const response = await fetch('https://api.microlink.io?url=https://www.getyourguide.com');
        const data = await response.json();
        setGygPreviewData(data.data);
      } catch (error) {
        console.error('Error fetching GYG preview:', error);
      }
    };
    fetchGygPreview();
  }, []);

  // Update localStorage when theme changes
  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    
    document.body.style.transition = 'background-color 0.2s ease, color 0.2s ease';
    if (isDarkMode) {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  // Easter egg handler
  const handleBuildClick = () => {
    setShowEasterEgg(true);
    setTimeout(() => {
      setShowEasterEgg(false);
    }, 2000);
  };

  // Update the useEffect for font storage
  useEffect(() => {
    localStorage.setItem('font', fontFamily);
  }, [fontFamily]);

  // Improved social expander trigger - only play sound here
  const handleSocialExpand = () => {
    if (!isSocialExpanded && !discoveredEggs.SOCIAL_EXPANDER) {
      discoverEgg('SOCIAL_EXPANDER');
    }
    setIsSocialExpanded(!isSocialExpanded);
  };

  // Improved version shimmer trigger
  const handleVersionShimmer = () => {
    if (!discoveredEggs.VERSION_SHIMMER) {
      discoverEgg('VERSION_SHIMMER');
    }
  };

  return (
    <motion.div className="relative min-h-screen">
      <motion.div
        className={`
          min-h-screen p-4 sm:p-8 md:p-16 
          max-w-xl mx-auto flex flex-col justify-center
          ${isDarkMode ? 'text-white' : 'text-gray-900'}
          transition-all duration-300 ease-in-out
          ${fontFamily === 'inter' ? 'font-["Inter"] tracking-tight' : 'font-default tracking-normal'}
        `}
      >
        <motion.div className="flex flex-col space-y-6 sm:space-y-8">
          {/* Restore Nav component */}
          <Nav 
            isDarkMode={isDarkMode} 
            setIsDarkMode={setIsDarkMode}
            setIsCommandPaletteOpen={setIsCommandPaletteOpen}
          />

          {/* Profile section */}
          <header className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-6">
            <div 
              className="relative w-14 h-14 sm:w-16 sm:h-16 rounded-full overflow-hidden flex-shrink-0"
              style={{ aspectRatio: '1 / 1' }}
            >
              <img 
                src={isDarkMode ? profilePic : profilePicLight} 
                alt="Prateek" 
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h1 className="text-base sm:text-l">
                <span className="font-bold">Hi, I am Prateek.</span>{' '}
                <span className={`font-normal ${isDarkMode ? 'text-gray-500' : 'text-gray-600'}`}>
                  I am a product marketer and creative based in Berlin, Germany.
                </span>
              </h1>
            </div>
          </header>

          {/* Main content area */}
          <main className="flex-1 overflow-y-auto space-y-6 sm:space-y-8 hide-scrollbar">
            <Experience 
              isDarkMode={isDarkMode}
              gygPreviewData={gygPreviewData}
              setActivePreview={setActivePreview}
            />

            <motion.section className="mb-6">
              <h2 className="text-gray-500 text-sm mb-3">ABOUT</h2>
              <p className="leading-relaxed text-sm">
              I do marketing. I also like making, leading, and taking things from 0 to 1. I have a deep passion for tasty tech and design. I am often learning and experimenting with AI, code, design, photo, and film. I value high agency, creativity, and big-picture thinking.
              </p>
            </motion.section>

            <motion.section>
              <h2 className="text-gray-500 text-sm mb-3">RECENT WORK</h2>
              <ul className="space-y-3">
                {PROJECTS.map((project, index) => (
                  <motion.li 
                    key={`${project.name}-${index}`}
                    className="cursor-pointer"
                  >
                    <ProjectCard 
                      project={project} 
                      isDarkMode={isDarkMode}
                      onHoverStart={(previewData) => setActivePreview(previewData)}
                      onHoverEnd={() => setActivePreview(null)}
                      previewData={previews[project.link]}
                    />
                  </motion.li>
                ))}
              </ul>
            </motion.section>
          </main>

          {/* Footer section */}
          <footer className="flex items-center justify-between pt-2 pb-16 sm:pb-2">
            <div className="relative">
              <motion.button
                onClick={handleSocialExpand}
                className={`
                  p-1 rounded-full group
                  ${isDarkMode 
                    ? 'hover:bg-white/[0.06]' 
                    : 'hover:bg-black/[0.03]'}
                  transition-colors duration-200
                `}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <PiLinkSimple className={`
                  w-3.5 h-3.5
                  ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}
                  ${isSocialExpanded ? 'opacity-50' : 'opacity-100'}
                  transition-opacity duration-200
                `} />
              </motion.button>

              <AnimatePresence>
                {isSocialExpanded && (
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    className="absolute left-full top-0 ml-2 flex items-center gap-2"
                  >
                    {[
                      { 
                        icon: FiMail, 
                        href: 'mailto:hi@prateekkeshari.com', 
                        label: 'Email' 
                      },
                      { icon: FiGithub, href: 'https://github.com/prateekkeshari', label: 'GitHub' },
                      { icon: BsTwitterX, href: 'https://twitter.com/prkeshari', label: 'Twitter' },
                      { icon: FiLinkedin, href: 'https://linkedin.com/in/prateekkeshari', label: 'LinkedIn' },
                      { icon: FaSquareThreads, href: 'https://www.threads.net/@prateekkeshari', label: 'Threads' }
                    ].map((link, index) => (
                      <motion.a
                        key={index}
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`
                          group relative p-1 rounded-full
                          ${isDarkMode 
                            ? 'text-gray-500 hover:text-gray-400' 
                            : 'text-gray-500 hover:text-gray-600'}
                          transition-colors duration-200
                        `}
                        initial={{ opacity: 0, x: 10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 10 }}
                        transition={{ delay: index * 0.05 }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <link.icon className="w-3.5 h-3.5" />
                        <span className={`
                          absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1
                          text-[11px] rounded whitespace-nowrap
                          opacity-0 group-hover:opacity-100 pointer-events-none
                          transition-opacity duration-200
                          ${isDarkMode ? 'bg-white/10 text-gray-300' : 'bg-black/5 text-gray-600'}
                        `}>
                          {link.label}
                        </span>
                      </motion.a>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="text-xs text-gray-500 flex items-center gap-2">
              <div className="w-2 h-2 rounded-full bg-green-500/60" />
              <span 
                className={`font-mono cursor-pointer ${shimmerClass}`} 
                onClick={handleVersionShimmer}
              >
                v1.0
              </span>
              <AnimatePresence>
                {showEasterEgg && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    className={`
                      absolute bottom-full right-0 mb-2 p-3 rounded-lg
                      ${isDarkMode ? 'bg-black/90' : 'bg-white/90'}
                      backdrop-blur-sm border text-xs
                      ${isDarkMode ? 'border-white/[0.08]' : 'border-black/[0.08]'}
                    `}
                  >
                    <div className="flex flex-col items-center gap-2">
                      <span className="font-mono">Built with React + Framer</span>
                      <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                        Made with ❤️ in Berlin
                      </span>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </footer>
        </motion.div>
      </motion.div>

      <AnimatePresence mode="wait">
        {activePreview && (
          <motion.div
            key={activePreview.url}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className={`
              fixed sm:absolute 
              left-4 sm:left-[calc(50%+16rem)] 
              right-4 sm:right-auto 
              ${gygPreviewData === activePreview ? 'top-24' : 'top-1/2 -translate-y-1/2'} 
              z-50 w-auto sm:w-[20rem] p-4 sm:p-5 
              rounded-xl shadow-2xl backdrop-blur-sm 
              ${isDarkMode 
                ? 'bg-black/95 border border-gray-800' 
                : 'bg-white/95 border border-gray-100'
              }
            `}
          >
            <motion.img 
              src={activePreview.image.url} 
              alt={activePreview.title}
              className="w-full h-40 object-cover rounded-lg mb-4"
            />
            
            <h3 className={`font-medium text-sm mb-2 ${
              isDarkMode ? 'text-gray-100' : 'text-gray-800'
            }`}>
              {activePreview.title}
            </h3>
            <p className={`text-xs leading-relaxed ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              {activePreview.description?.length > 80 
                ? `${activePreview.description.slice(0, 80)}...` 
                : activePreview.description}
            </p>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className={`
          md:hidden fixed right-4 bottom-4 p-2.5 rounded-full shadow-lg
          flex items-center justify-center z-10
          ${isDarkMode 
            ? 'bg-white/10 hover:bg-white/20 backdrop-blur-sm border border-white/20' 
            : 'bg-gray-900/90 hover:bg-gray-900 backdrop-blur-sm'}
          transition-colors duration-200
        `}
        onClick={() => setIsCommandPaletteOpen(true)}
      >
        <HiCommandLine className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-white'}`} />
      </motion.button>

      <CommandPalette 
        isOpen={isCommandPaletteOpen}
        setIsOpen={setIsCommandPaletteOpen}
        isDarkMode={isDarkMode}
        projects={PROJECTS}
        setIsDarkMode={setIsDarkMode}
      />
      
      <Analytics />
    </motion.div>
  );
}

export default App;
